angular.module('LeasePilot').directive('comments', [
  '$rootScope',
  '$compile',
  function($rootScope, $compile) {
    var _commentsManager = null;
    var _editorHelper = null;
    var _pidMap = {};
    var _leaseEditorElement = null;
    var _addBtnElement = null;
    var _mutationObserverConfig = {
      characterData: true,
      subtree: true,
      childList: true,
      attributes: true,
    };
    var _observer = null;
    var $scope = null;

    var getElementAbsoluteOffset = el => {
      if (!el) {
        return 0;
      }

      var ret = el.offsetTop;
      while (el.offsetParent) {
        el = el.offsetParent;
        ret += el.offsetTop;
      }

      return ret;
    };

    var getElementOffset = el => {
      if (!el) {
        return 0;
      }

      var ret = el.offsetTop;
      if (el.offsetParent === _leaseEditorElement) return ret;
      else {
        while (el.offsetParent && el.offsetParent !== _leaseEditorElement) {
          el = el.offsetParent;
          ret += el.offsetTop;
        }
      }
      return ret;
    };

    var updateCommentsOnScope = data => {
      if (typeof data === 'string') {
        data = JSON.parse(data);
      }

      if (data.comments && !data.errors) {
        data = _commentsManager.getMapFromCommentsArray(data);
        $scope.comments = data;
        $scope.commentsPids = Object.keys(data);
        $scope.newCommentText = '';
        if ($scope.currentCommentPid) {
          $scope.currentCommentsData = data[$scope.currentCommentPid];
        }
      } else {
        console.log(`Error in getting or updating comments ${data.errors}`);
      }

      $scope.safeApply();
      updateExistingCommentsMarkersPosition();
    };
    var getLeaseComments = (leaseId, scope) => {
      _commentsManager.get(leaseId).then(updateCommentsOnScope);
    };

    var elementPolyfillCallBack = (el, pid, clientSideGenerated) => {
      if (_pidMap[pid]) {
        console.log(
          `Double PID !!! ${pid} origen from client : ${clientSideGenerated}`,
        );
      } else {
        _pidMap[pid] = el;
      }
    };

    var getMouseMoveTargetPid = evt => {
      var el = evt.srcElement;
      if (el) {
        while (el.parentElement && !el.getAttribute('pid')) {
          el = el.parentElement;
        }
        if (el.getAttribute('pid')) {
          return el;
        }
      }
      return null;
    };

    var _updateMarkersTimeOut = null;

    var updateExistingCommentsMarkersPosition = () => {
      var markers = document.querySelectorAll('.comments-marker');
      if (markers && markers.length > 0) {
        for (var i = 0; i < markers.length; i++) {
          var marker = markers[i];
          var pidel = _pidMap[marker.getAttribute('commentpid')];
          marker.style.top = `${getElementOffset(pidel) + 5}px`;
          marker.style.display = 'block';
        }
        updateCommentsDisplayAndEditorPosition();
      }
      updateAcceptRejectItemsPosition();
    };
    

    var updateCommentsDisplayAndEditorPosition = () => {
      if ($scope.currentCommentPid && _pidMap[$scope.currentCommentPid]) {
        var el = document.querySelector('#comments-display-warper');
        el.style.top = `${getElementOffset(
          _pidMap[$scope.currentCommentPid],
        )}px`;
      }
    };

    var updateAddCommentBtnPosition = evt => {
      if (_addBtnElement === null)
        _addBtnElement = document.querySelector('.addCommentBtn');
      if (_addBtnElement === null) return;
      var target = getMouseMoveTargetPid(evt);
      if (
        target &&
        $scope.comments &&
        !$scope.comments[target.getAttribute('pid')]
      ) {
        _addBtnElement.style.display = 'block';
        _addBtnElement.setAttribute('commentpid', target.getAttribute('pid'));
        _addBtnElement.style.top = `${getElementOffset(target) + 3}px`;
      } else if (target) {
        _addBtnElement.style.display = 'none';
      }
    };

    var hideComments = pid => {
      _pidMap[pid].classList.remove('selectedPidContainer');
      $scope.currentCommentPid = null;
      $scope.currentCommentsData = null;
      $scope.showComments = false;
      $scope.safeApply();
      document.body.classList.remove('showCommentsEditor');
    };

    var showComments = pid => {
      if ($scope.currentCommentPid) {
        _pidMap[$scope.currentCommentPid].classList.remove(
          'selectedPidContainer',
        );
      }
      $scope.currentCommentPid = pid;
      $scope.currentCommentsData = $scope.comments[pid];
      $scope.showComments = true;
      $scope.safeApply();
      document.body.classList.add('showCommentsEditor');
      _pidMap[pid].classList.add('selectedPidContainer');
      updateCommentsDisplayAndEditorPosition();
    };

    var addShowCommentsClick = evt => {
      var pid = evt.originalEvent.srcElement.getAttribute('commentpid');
      if ($scope.showComments) {
        if ($scope.currentCommentPid === pid) {
          hideComments(pid);
        } else {
          showComments(pid);
        }
      } else {
        showComments(pid);
      }
    };

    var deleteComment = commentId => {
      _commentsManager.delete(commentId).then(updateCommentsOnScope);
    };

    var addNewComment = () => {
      if (!$scope.newCommentText || $scope.newCommentText.trim().length === 0)
        return;
      var pid = $scope.currentCommentPid;
      var parentCommentId = null;
      if ($scope.comments[pid] && $scope.comments[pid].length !== 0) {
        var index = 0;
        while ($scope.comments[pid][index] && parentCommentId === null) {
          if (
            $scope.comments[pid][index].status === 'open' &&
            !$scope.comments[pid][index].parent_comment_id
          ) {
            parentCommentId = $scope.comments[pid][index].id;
          }
          index++;
        }
      }
      _commentsManager
        .set($scope.lease.id, $scope.newCommentText, parentCommentId, pid)
        .then(updateCommentsOnScope);
    };

    var clearNewCommentText = () => {
      $scope.newCommentText = '';
      $scope.safeApply();
    };

    var updateAcceptRejectItemsPosition = () => {
      stopObserving();
      var elements = document.querySelectorAll('.annotation');
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        element.classList.remove('annotation--add-space');
        var dataItem = $scope.annotations[elements[i].getAttribute('data-pid')];

        if (dataItem) {

          var anchorElementTop = getElementAbsoluteOffset(dataItem.container);
          if(anchorElementTop <=0){
            let container = document.querySelector('[pid="'+ dataItem.container.getAttribute('pid')+ '"]')
            anchorElementTop = getElementAbsoluteOffset(container);

          }
          var currentTop = getElementAbsoluteOffset(element);
          if (currentTop !== anchorElementTop) {
            var containerTop = getElementAbsoluteOffset(element.parentElement);
            element.style.top = `${anchorElementTop - containerTop}px`;
            currentTop = getElementAbsoluteOffset(element);
          }
          if (currentTop < 150) {
            element.classList.add('annotation--add-space');
          }
        }
      }
      startObserving();
    };

    var startObserving = () => {
      _observer.observe(document.body, _mutationObserverConfig);
    };

    var stopObserving = () => {
      _observer.disconnect();
    };

    function getDiffItem(index) {
      return document.querySelector(`.diff-item[diff-index="${index}"]`);
    }

    function findLeaseVar(diffItemNodes) {
      let leaseVarNode;

      for (let node of diffItemNodes) {
        const parent = node.closest('.transformed--lease-var:not([non-click="true"])');

        if (parent) {
          leaseVarNode = parent;
          break;
        }
      }

      return leaseVarNode;
    }

    var acceptAll = async annotation => {
      const appScope = $rootScope.findAppScope();
      await appScope.annotationsHelper.actions.acceptAndSave(annotation);
      annotation.accepted = true;
      annotation.rejected = false;
      annotation.status = 'resolved';

      window.track.event(new AcceptAllTenantRequestsEvent({
        context: $rootScope.getContext(),
      }));
    };

    var acceptRequest = async (annotation, request) => {
      const appScope = $rootScope.findAppScope();
      const diffItem = getDiffItem(request._index);

      const requestIndex = annotation.items.indexOf(request);
      if (requestIndex !== annotation.items.length - 1) {
        appScope.annotationsHelper.jumpToRequest(
          annotation.items[requestIndex + 1]._index,
        );
      }

      await appScope.annotationsHelper.actions.acceptAndSave(diffItem);

      const resolvedItems = annotation.items.filter(
        item => item._status !== 'unresolved',
      );
      if (resolvedItems.length === annotation.items.length) {
        const accepted = resolvedItems.filter(
          item => item._status === 'accepted',
        );
        const rejected = resolvedItems.filter(
          item => item._status === 'rejected',
        );

        if (accepted.length === annotation.items.length) {
          annotation.accepted = true;
        } else if (rejected.length === annotation.items.length) {
          annotation.rejected = true;
        } else {
          annotation.manual = true;
        }

        annotation.status = 'resolved';

        window.track.event(new AcceptIndividualTenantRequestEvent({
          context: $rootScope.getContext(),
        }));
      }
    };

    var rejectAll = async annotation => {
      const appScope = $rootScope.findAppScope();
      await appScope.annotationsHelper.actions.rejectAndSave(annotation);

      annotation.rejected = true;
      annotation.accepted = false;
      annotation.status = 'resolved';

      window.track.event(new RejectAllTenantRequestsEvent({
        context: $rootScope.getContext(),
      }));
    };

    var rejectRequest = async (annotation, request) => {
      const appScope = $rootScope.findAppScope();
      const diffItem = getDiffItem(request._index);

      const requestIndex = annotation.items.indexOf(request);
      if (requestIndex !== annotation.items.length - 1) {
        appScope.annotationsHelper.jumpToRequest(
          annotation.items[requestIndex + 1]._index,
        );
      }

      await appScope.annotationsHelper.actions.rejectAndSave(diffItem);

      const resolvedItems = annotation.items.filter(
        item => item._status !== 'unresolved',
      );
      if (resolvedItems.length === annotation.items.length) {
        const accepted = resolvedItems.filter(
          item => item._status === 'accepted',
        );
        const rejected = resolvedItems.filter(
          item => item._status === 'rejected',
        );

        if (accepted.length === annotation.items.length) {
          annotation.accepted = true;
        } else if (rejected.length === annotation.items.length) {
          annotation.rejected = true;
        } else {
          annotation.manual = true;
        }

        annotation.status = 'resolved';

        window.track.event(new RejectIndividualTenantRequestEvent({
          context: $rootScope.getContext(),
        }));
      }
    };

    var adjustManually = annotation => {
      const appScope = $rootScope.findAppScope();
      const diffItemNodes = document.querySelectorAll(
        `.lease.lease--modified .annotation[data-pid="${annotation.pid}"] .diff-item`,
      );
      const element = findLeaseVar(diffItemNodes);
      const leaseVar = element.getAttribute('var');

      if (appScope.inline.on) {
        var relateds = appScope.inline.findNestedControls(element);

        // remove yourself from array and reposition at beginning
        var myIdx = relateds.indexOf(leaseVar);
        if (myIdx > -1) {
          relateds.splice(myIdx, 1);
        }
        relateds.unshift(leaseVar);

        relateds = appScope.inline.util.checkRelateds(relateds, element);
        appScope.inline.openInline(element, relateds);

        window.track.event(new AdjustAllTenantRequestsManuallyEvent({
          context: $rootScope.getContext(),
        }));
      }
    };

    var adjustRequestManually = (annotation, request) => {
      const appScope = $rootScope.findAppScope();
      const diffItemNodes = document.querySelectorAll(
        `.lease.lease--modified .diff-item[diff-index="${request._index}"]`,
      );
      const element = findLeaseVar(diffItemNodes);
      const leaseVar = element.getAttribute('var');

      if (appScope.inline.on) {
        var relateds = appScope.inline.findNestedControls(element);

        // remove yourself from array and reposition at beginning
        var myIdx = relateds.indexOf(leaseVar);
        if (myIdx > -1) {
          relateds.splice(myIdx, 1);
        }
        relateds.unshift(leaseVar);

        relateds = appScope.inline.util.checkRelateds(relateds, element);
        appScope.inline.openInline(element, relateds);

        window.track.event(new AdjustIndividualTenantRequestManuallyEvent({
          context: $rootScope.getContext(),
        }));
      }
    };

    var markAsResolved = annotation => {
      const appScope = $rootScope.findAppScope();

      let currentRequest;
      let currentAnnotationItem;
      for (let i = 0; i < annotation.items.length; i++) {
        currentRequest = annotation.items[i];
        currentAnnotationItem = getDiffItem(currentRequest._index);
        appScope.annotationsHelper.resolveAnnotation(
          currentAnnotationItem,
          'manual',
        );
      }

      annotation.manual = true;
      annotation.status = 'resolved';

      window.track.event(new MarkAllTenantRequestsAsResolvedEvent({
        context: $rootScope.getContext(),
      }));
    };

    var markRequestAsResolved = (annotation, request) => {
      const appScope = $rootScope.findAppScope();
      const currentAnnotationItem = getDiffItem(request._index);

      const requestIndex = annotation.items.indexOf(request);
      if (requestIndex !== annotation.items.length - 1) {
        appScope.annotationsHelper.jumpToRequest(
          annotation.items[requestIndex + 1]._index,
        );
      }

      appScope.annotationsHelper.resolveAnnotation(
        currentAnnotationItem,
        'manual',
      );

      const resolvedItems = annotation.items.filter(
        item => item._status !== 'unresolved',
      );
      if (resolvedItems.length === annotation.items.length) {
        const accepted = resolvedItems.filter(
          item => item._status === 'accepted',
        );
        const rejected = resolvedItems.filter(
          item => item._status === 'rejected',
        );

        if (accepted.length === annotation.items.length) {
          annotation.accepted = true;
        } else if (rejected.length === annotation.items.length) {
          annotation.rejected = true;
        } else {
          annotation.manual = true;
        }

        annotation.status = 'resolved';

        window.track.event(new MarkIndividualTenantRequestAsResolvedEvent({
          context: $rootScope.getContext(),
        }));
      }
    };

    var preview = () => {
      const appScope = $rootScope.findAppScope();
      appScope.annotationsHelper.preview();

      window.track.event(new PreviewTenantRequestsEvent({
        context: $rootScope.getContext(),
      }));
    };

    function comments(scope, element, attr) {
      $scope = scope;
      $scope.getElementAbsoluteOffset = getElementAbsoluteOffset;
      $scope.commentsType = attr.type ? attr.type : 'comments';
      let _leaseEditorElement;
      if (attr.type === 'accept-reject') {
        $scope.acceptAll = acceptAll;
        $scope.acceptRequest = acceptRequest;
        $scope.rejectAll = rejectAll;
        $scope.rejectRequest = rejectRequest;
        $scope.adjustManually = adjustManually;
        $scope.adjustRequestManually = adjustRequestManually;
        $scope.markAsResolved = markAsResolved;
        $scope.markRequestAsResolved = markRequestAsResolved;
        $scope.preview = preview;
        _leaseEditorElement = document.querySelector('.lease.lease--modified');
      } else {
        $scope.clearNewCommentText = clearNewCommentText;
        $scope.deleteComment = deleteComment;
        $scope.currentCommentsData = null;
        $scope.currentCommentPid = null;
        $scope.showComments = false;
        $scope.addNewComment = addNewComment;
        $scope.addShowCommentsClick = addShowCommentsClick;
        $scope.newCommentText = '';
        libx.di.requireUgly(
          ['editorHelper', 'CommentsService'],
          (editorHelper, CommentsService) => {
            _editorHelper = editorHelper;
            _editorHelper.addFirstTextIdPolyfill(elementPolyfillCallBack);
            _commentsManager = new CommentsService();
            getLeaseComments(scope.lease.id, scope);
          },
        );
        _leaseEditorElement = $('.lease')
          .not('.lease--modified')
          .get(0);
      }
      _observer = new MutationObserver(
        _.throttle(updateExistingCommentsMarkersPosition, 250, {
          leading: false,
          trailing: true,
        }),
      );
      startObserving();
      // updateExistingCommentsMarkersPosition();
      window.addEventListener(
        'resize',
        _.throttle(updateExistingCommentsMarkersPosition, 250, {
          leading: false,
          trailing: true,
        }),
      );
      _leaseEditorElement.addEventListener(
        'mouseover',
        _.throttle(updateAddCommentBtnPosition, 250, {
          leading: false,
          trailing: true,
        }),
      );
    }
    return {
      link: comments,
      restrict: 'E',
      templateUrl: 'comments.html',
    };
  },
]);
